code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media all and (max-width: 480px) {
  .MuiFormControl-root {
    width: 100%;
  }
  body {
    padding: 0!important;
  }
}
